<template>
  <header class="desktop">
    <LinkRail />
    <SearchRail />
    <NavigationRailTreatment v-if="enhancedDesktopNavFeature" />
    <NavigationRail v-else />
  </header>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';

import LinkRail from '../link-rail/LinkRail.mcom';
import NavigationRailTreatment from './NavigationRailTreatment.common';
import NavigationRail from './NavigationRail.common';
import SearchRail from './SearchRail.mcom';
import {
  onUserInit, onKsFlagsReady, onKsFlagsFetched, onAllPassedEventsReady,
} from '../../events';
import { GET_ZIP_CODE } from '../../types/getters';
import { FETCH_PREFERRED_STORE, FETCH_SDD_MESESSAGE } from '../../types/actions';
import { useRegistry } from '../../composables/useRegistry';
import { storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';

import useFeatureEligibility from '../../composables/useFeatureEligibility';

const store = useStore();

const { isMcom } = store.state.envProps;
const { fetchRegistries } = useRegistry({ checkUpdates: true });
const { enhancedDesktopNavFeature } = useFeatureEligibility();

const zipCode = computed(() => store.getters[`navPreferredStore/${GET_ZIP_CODE}`]);
const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);

const fetchPreferredStore = async () => {
  await store.dispatch(`navPreferredStore/${FETCH_PREFERRED_STORE}`);
  if (zipCode.value) store.dispatch(`navPreferredStore/${FETCH_SDD_MESESSAGE}`, zipCode.value);
};

const fetchLoyalPoints = () => {
  if (isSignedIn.value) {
    store.dispatch('navLoyaltyStore/fetchRewardsData');
  } else {
    storeLoyaltyStatusForLegacy({}, isMcom);
  }
};

onUserInit(fetchPreferredStore);
onAllPassedEventsReady(
  [onKsFlagsFetched, onKsFlagsReady, onUserInit],
  () => {
    fetchRegistries();
    fetchLoyalPoints();
  },
);
</script>
