<template>
  <li
    v-if="isSignedIn && experiments.accountDropdownEnabled"
    class="full-height"
  >
    <button
      class="link-rail-item grid-x align-middle full-height"
      @click="openAccountSlideout"
    >
      {{ dropdownText }}
    </button>
  </li>
  <LinkRailMenuItem
    v-else-if="isSignedIn"
    :prefix-icon="dropdownIcon"
    min-width="250px"
    with-chevron
  >
    <template #default="{ active }">
      <Link
        class="link-rail-item"
        :class="{ active }"
        :href="dropdownLink"
        :tracking="dropdownLinkTracking"
        @click.prevent="handleAccountLinkClick"
      >
        {{ dropdownText }}
      </Link>
    </template>
    <template #content="{ opened }">
      <AccountDropdownContent v-if="opened" />
    </template>
  </LinkRailMenuItem>
  <li
    v-else
    class="relative"
  >
    <Link
      class="link-rail-item signin-rail-item"
      data-testid="signInLink"
      :href="dropdownLink"
      :tracking="dropdownLinkTracking"
      @click="handleAccountLinkClick"
      @focusin="deactivateSignInTooltip"
    >
      <span class="profile margin-right-xxs" />
      <span>Sign In</span>
    </Link>
    <SignInTooltip v-if="isSignInTooltipActive">
      <template #default="{ suspendTimer, resumeTimer }">
        <span class="small">See more of <br> what you love</span>
        <a
          :href="dropdownLink"
          class="primary button button-small"
          @focusin="suspendTimer"
          @focusout="resumeTimer"
          @click="useSignInClick({ tooltipTags: true })"
        >
          Sign In
        </a>
      </template>
    </SignInTooltip>
  </li>
</template>

<script setup>
/* istanbul ignore file */
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import Links from '../../constants/Links';
import Link from '../common/Link';
import LinkRailMenuItem from './LinkRailMenuItem.common';
import SignInTooltip from './SignInTooltip.common';
import { tagAccountLink } from '../../features/analytics/linkRailTags';
import { useSignInTooltipActivator } from '../../composables/features/useSignInTooltip';
import useAccountSlideout from '../../composables/features/useAccountSlideout';
import useLoyalty from '../../composables/useLoyalty';
import useSignInClick from '../../composables/useSignInClick';
import useExperiments from '../../composables/useExperiments';

const AccountDropdownContent = defineAsyncComponent(() => import('./AccountDropdownContent.common'));

const store = useStore();

const experiments = useExperiments();
const { loyaltyIcon } = useLoyalty();
const username = computed(() => store.state.navUser.data.username);
const isSignedIn = computed(() => store.state.navUser.data.isSignedIn);
const isDomestic = computed(() => store.state.pageData.navigation.context.isDomestic);
const dropdownText = computed(() => (isSignedIn.value ? `Hi, ${username.value}` : 'Sign In'));
const dropdownLink = computed(() => (isSignedIn.value ? Links.homepage.account.url : Links.account.signIn.url));
const dropdownLinkTracking = Links.homepage.account.tracking;
const dropdownIcon = computed(() => {
  if (!isSignedIn.value) return 'profile';
  return loyaltyIcon.value.replace('large', 'small');
});
const { isSignInTooltipActive, deactivateSignInTooltip } = useSignInTooltipActivator({ isSignedIn, isDomestic });
const { openAccountSlideout } = useAccountSlideout();

function handleAccountLinkClick() {
  if (!isSignedIn.value) {
    useSignInClick();
  }
  tagAccountLink();
}
</script>

<style lang="scss" brand="mcom">
@import "../../scss/icons.mcom.scss";

$profile-icon-size: 16px;

.link-rail {
  .signin-rail-item {
    display: flex;
    align-items: center;
  }

  .profile {
    width: $profile-icon-size;
    height: $profile-icon-size;
    background-size: $profile-icon-size;
  }
}
</style>
