import { computed } from 'vue';
import { dataSources, viewTypes, viewTypeToDeviceType } from '../constants';
import { useHeaderFetch } from './dumb/useNavigationFetch';
import { SET_FETCH_ERROR, SET_HEADER_DATA } from '../types/mutations';
import { logWarn } from '../utils/Logger';
import { getKillswitchConfig, parseAEMData } from '../store_modules/headerStore/helpers';
import { LOAD_KILLSWITCHES } from '../features/killswitches/types/actions';

import useExperimentsIds from './useExperimentsIds';

function buildDataSourceContext(dataSource) {
  const context = {
    ccsHeader: [dataSources.aem, dataSources.aemWithMenuFromStella].includes(dataSource),
    removeCmFromUrls: true,
  };

  if (context.ccsHeader) {
    context.menuFromStella = true;
  }

  return context;
}

// passing _store is needed for tests
export function useHeaderMenuData(store) {
  const { isMcom } = store.state.envProps;
  const experimentIds = useExperimentsIds();
  const viewType = computed(() => store.state.navViewTypeStore.viewType);

  function getDataSource() {
    if (viewType.value === viewTypes.desktop) {
      return dataSources.aem;
    }

    return dataSources.aemWithMenuFromStella;
  }

  function getIsMenuFromStella() {
    return getDataSource() === dataSources.aemWithMenuFromStella;
  }

  function getMenuData() {
    return store.state.headerStore.data[viewType.value]?.menu || {};
  }

  function getCategoryListFromMenuByIds(menu, ids) {
    return ids.reduce((acc, id) => {
      const category = menu[id];

      if (category) {
        acc.push(category);
      }

      return acc;
    }, []);
  }

  function getBcomTopLevelFromMenuByIds(menu) {
    return Object.values(menu)
      .reduce((acc, category) => {
        if (!category.parentId) {
          acc.push(category);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if ('order' in a && 'order' in b) {
          return a.order - b.order;
        }
        return 0;
      });
  }

  function getGroupsTopCategories() {
    const menu = getMenuData();
    const topGroupsMenu = store.state.headerStore.data[viewType.value]?.meta?.properties.topGroupsMenu || [];

    return topGroupsMenu.reduce((acc, cur) => {
      const { title, ids } = cur;
      const data = getCategoryListFromMenuByIds(menu, ids);

      if (!data.length) return acc;

      const group = { title, data };

      return acc.concat(group);
    }, []);
  }

  function getIsAemMenu() {
    return getDataSource() === dataSources.aem;
  }

  function getMobileTopCategories() {
    const menu = getMenuData();

    if (isMcom) {
      const topMenuIds = store.state.headerStore.data[viewType.value]?.meta?.properties?.topMenu?.split(',') || [];

      return getCategoryListFromMenuByIds(menu, topMenuIds);
    }

    return getBcomTopLevelFromMenuByIds(menu);
  }

  async function processFetchedData(result, isFetchSucceed) {
    const rootState = store.state;
    const { context } = rootState.pageData.navigation;
    const _viewType = viewType.value;
    const isMobile = _viewType === viewTypes.mobile;
    let data;

    if (isFetchSucceed) {
      data = result;
      store.commit(`headerStore/${SET_FETCH_ERROR}`, false);
    } else {
      logWarn('[navigation] Failed to request header data from headerfooter-xapi, using mock data instead.');
      const { getHeaderFallback } = await import(/* webpackChunkName: "XapiFallback" */ '../utils/XapiFallback');
      data = (await getHeaderFallback({ ...context, isMcom, isMobile })).value;
      // disable enhanced desktop functionality as mock fallback data is not compatible with this
      data.header.meta.properties.enhancedDesktopNavEnabled = false;
      store.commit(`headerStore/${SET_FETCH_ERROR}`, true);
      rootState.envProps.isNavMockHeader = true;
    }

    const isAemMenu = getIsAemMenu();
    const transformedData = parseAEMData(data, rootState, isAemMenu);

    store.dispatch(`navKillswitchStore/${LOAD_KILLSWITCHES}`, getKillswitchConfig(transformedData));
    // remove duplicated killswitches to reduce memory usage and SRR response size
    const { topMenu, menuLabels = [] } = transformedData.meta.properties;
    const topGroupsMenu = menuLabels.reduce((acc, key) => acc.concat({
      title: key,
      ids: transformedData.meta.properties[key],
    }), []);
    transformedData.meta.properties = { topMenu, topGroupsMenu };
    store.commit(`headerStore/${SET_HEADER_DATA}`, { viewType: _viewType, data: transformedData });
  }

  async function fetch(force) {
    if (!force && Object.keys(getMenuData()).length) {
      return;
    }

    const rootState = store.state;
    const { context } = rootState.pageData.navigation;
    const { hostlink: clientBaseUrl, headerFooterXapiHost: serverBaseUrl } = rootState.envProps;
    const deviceType = viewTypeToDeviceType[viewType.value];
    const dataSourceContext = buildDataSourceContext(getDataSource(viewType.value));
    const customerExperiments = experimentIds.value.length ? { customerExperiment: experimentIds.value } : {};
    const { fetch: headerFetch, result } = useHeaderFetch({
      ...context,
      ...dataSourceContext,
      ...customerExperiments,
      clientBaseUrl,
      serverBaseUrl,
      deviceType,
    });

    await headerFetch();
    const isFetchSucceed = result.value && typeof result.value === 'object';

    await processFetchedData(result.value, isFetchSucceed, viewType.value);
  }

  return {
    getMenuData,
    getMobileTopCategories,
    getGroupsTopCategories,
    getIsMenuFromStella,
    getIsAemMenu,
    getDataSource,
    fetch,
    processFetchedData,
  };
}

export default {};
