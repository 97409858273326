<template>
  <div
    v-show="currentFob"
    :id="currentFob.text"
    class="flyout-container"
    @keydown.esc.prevent="emit('close')"
    @keydown.arrow-down.prevent="onKeydownArrowDown"
    @keydown.arrow-up.prevent="onKeydownArrowUp"
    @keydown.tab.prevent="onKeydownTab"
    @keydown.arrow-right.prevent="onKeydownArrowRight($event, currentFob.text)"
    @keydown.arrow-left.prevent="onKeydownArrowLeft($event, currentFob.text)"
  >
    <div
      v-show="isActive"
      v-if="currentFob.children.length"
      class="grid-container"
      :data-testid="`flyout-${currentFob.text}`"
    >
      <div class="wrapper grid-x">
        <ul
          v-for="(children, childIndex) in currentFob.children"
          :key="childIndex"
          class="category-cell grid-y"
          :class="{'has-image': hasImage(currentFob.children)}"
        >
          <template
            v-for="(group, groupIndex) in (children)"
            :key="groupIndex"
          >
            <li
              v-if="group.children || isImageContent(group)"
              class="category-group"
              :class="{'flyout-image-wrapper':isImageContent(group)}"
              :data-id="group.id"
            >
              <template v-if="isImageContent(group)">
                <component
                  :is="group.imageLink ? Link : 'div'"
                  :href="group.imageLink"
                  :data-tracking="trackingObjToString(group.imageLinkTracking)"
                  class="image-link"
                  @click="group.imageLink && tagNavLinkClick({
                    categoryId: getCategoryId(null, group.id, group.imageLink),
                    clickableElement: 'Shop Now',
                    col: childIndex + 1,
                    pos: getLinkPositionInColumn($event),
                    context,
                    topNavCategory: flyoutId,
                    tracking: group.imageLinkTracking
                  })"
                >
                  <Picture
                    :image-url="group.src"
                    :alt="group.text"
                    :using-scene7="false"
                  />
                </component>
                <Link
                  v-if="group.url && group.text"
                  class="menu-link-heavy under-image-link"
                  :href="group.url"
                  :data-tracking="trackingObjToString(group.linkTracking)"
                  @click="tagNavLinkClick({
                    categoryId: getCategoryId(null, group.id, group.url),
                    clickableElement: group.text,
                    col: childIndex + 1,
                    pos: getLinkPositionInColumn($event),
                    context,
                    topNavCategory: flyoutId,
                    tracking: group.linkTracking,
                  })"
                >
                  {{ group.text }}
                </Link>
              </template>

              <template v-else-if="group.children">
                <Link
                  v-if="group.url && group.text"
                  class="menu-link-heavy subcategory-header-link"
                  :class="{ 'subcategory-link': group.children.length }"
                  :style="group.color && { color: group.color, borderColor: group.color }"
                  :href="group.url"
                  :data-tracking="trackingObjToString(group.tracking)"
                  @click="tagNavLinkClick({
                    categoryId: getCategoryId(null, group.id, group.url),
                    clickableElement: group.text,
                    col: childIndex + 1,
                    pos: getLinkPositionInColumn($event),
                    context,
                    topNavCategory: flyoutId,
                    tracking: group.tracking
                  })"
                >
                  {{ group.text }}
                </Link>
                <h5
                  v-else-if="group.text"
                  class="heavy"
                  :style="group.color && { color: group.color, borderColor: group.color }"
                >
                  {{ group.text }}
                </h5>

                <ul class="links grid-y">
                  <li
                    v-for="(link, index) in group.children"
                    :key="link.id + index"
                  >
                    <Link
                      :id="link.id"
                      :href="link.url"
                      class="menu-link-sm link"
                      :data-tracking="trackingObjToString(link.tracking)"
                      :style="link.color && { color: link.color, borderColor: link.color }"
                      @click="tagNavLinkClick({
                        categoryId: getCategoryId(link.id, group.id, link.url),
                        clickableElement: link.text,
                        col: childIndex + 1,
                        pos: getLinkPositionInColumn($event),
                        context,
                        topNavCategory: flyoutId,
                        tracking: link.tracking
                      })"
                    >
                      {{ link.text }}
                    </Link>
                  </li>
                </ul>
              </template>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Picture from '@atomic-ui/picture';

import Link from '../common/Link';
import { useFlyout } from '../../composables/accessibility/useFlyout';
import { tagNavLinkClick } from '../../features/analytics/navigationTags';
import { useHeaderMenuData } from '../../composables/useHeaderMenuData';
import { trackingObjToString } from '../../utils/restoreMenuFromDom';

const store = useStore();

const { context } = store.state.pageData.navigation;

const emit = defineEmits(['close']); // eslint-disable-line no-undef

const props = defineProps({ // eslint-disable-line no-undef
  flyoutId: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const {
  onKeydownArrowDown,
  onKeydownArrowUp,
  onKeydownArrowRight,
  onKeydownArrowLeft,
  onKeydownTab,
} = useFlyout();
const { getMenuData } = useHeaderMenuData(store);

const currentFob = computed(() => getMenuData().find((fob) => fob.text === props.flyoutId));

function isImageContent(group) {
  return Boolean(group.isImage);
}

function hasImage(groups) {
  return groups.some(isImageContent);
}

function getLinkPositionInColumn({ currentTarget }) {
  const categoryCell = currentTarget.closest('.category-cell');
  const arrOfLinks = categoryCell.querySelectorAll('a');
  const targetIndex = Array.from(arrOfLinks).indexOf(currentTarget);

  return targetIndex + 1;
}

/*
 * Returns the category ID of the current link for analytics.
 * First it will check if the link id is numeric, and if so then it returns the id
 * Otherwise it will try to parse id from the url
 * Otherwise it will check if the group id is numeric, and if so then it returns the id
 * In the worst case it will return flyout id
 *
 * @param {string} linkId - The ID of the link.
 * @param {string} groupId - The ID of the group.
 * @param {string} url - The URL of the link.
 * @returns {string} - The category ID.
 */
function getCategoryId(linkId, groupId, url) {
  const regexp = /^\d+$/;

  if (linkId && regexp.test(linkId)) {
    return linkId;
  }

  const idInUrlMatch = url.match(/id=(\d+)/);
  if (idInUrlMatch) {
    return idInUrlMatch[1];
  }

  if (groupId && regexp.test(groupId)) {
    return groupId;
  }

  return currentFob.value.id;
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins';

.flyout-container {
  position: absolute;
  width: 100%;
  top: calc(100% + 1px);
  z-index: 1002;
  background-color: $white;
}

.grid-container {
  position: relative;
  @include padding-top-m;
  @include padding-bottom-m;

  @include breakpoint(mlarge up) {
    padding-left: 54px;
    padding-right: 54px;
  }
}

h5 {
  margin-bottom: 16px;
}

.subcategory-link {
  margin-bottom: 16px;
  display: block;
}

.wrapper {
  gap: 48px;
  flex-wrap: nowrap;
}

.category-cell {
  gap: 32px;
  flex-basis: 20%;
  max-width: 158px;
  @include breakpoint(mlarge up) {
    max-width: 236px;
  }

  &.has-image {
    gap: 16px;
  }
}

.links {
  gap: 12px;
}

.link, .image-link {
  display: block;
}

.under-image-link {
  display: block;
  margin-top: 16px;
}

.image {
  width:100%;
}
</style>

<style lang="scss" brand="mcom" scoped>
.link {
  color: $dashboard-color;
}

.sale-links {
  .link {
    color: $promotions-color;
    border-color: $promotions-color;
  }
}
</style>

<style lang="scss" brand="bcom" scoped>
.link {
  font-weight: 300px;
}

.flyout-image-wrapper:hover+.category-group {
  text-decoration: underline;
}
</style>
