<template>
  <Link
    class="nav-logo"
    :class="{ 'modal-active': isSearchModalActive }"
    :href="url"
    :tracking="tracking"
    :aria-label="logoAriaLabel"
  >
    <Picture
      :image-url="logoUrl"
      :using-scene7="false"
      :lazy-load="false"
      :fetch-priority="'low'"
      alt=""
    />
  </Link>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Picture from '@atomic-ui/picture';
import { Links, logoLabel } from 'config';
import Link from './Link';
import { GET_SEARCH_MODAL_STATE } from '../../types/getters';
import getAssetImage from '../../utils/getAssetImage';

const store = useStore();
const isSearchModalActive = computed(() => store.getters[`headerStore/${GET_SEARCH_MODAL_STATE}`]);
const logoAriaLabel = logoLabel.site;
const { tracking, url } = Links.homepage.site;
const logoUrl = getAssetImage('logo.svg');
</script>

<style lang="scss">
.nav-logo {
  display: block;
  position: relative;
  width: fit-content;

  picture {
    display: block;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  @include breakpoint(large up) {
    z-index: 1;

    &.modal-active {
      z-index: 12;
    }
  }
}
</style>

<style lang="scss" brand="mcom">
.nav-logo picture {
  width: 141px;
  height: 40px;
}
</style>

<style lang="scss" brand="bcom">
.nav-logo {
  picture {
    width: 155px;
    height: 24px;
  }

  @include breakpoint(large up) {
    top: 5px;

    picture {
      width: 263px;
      height: 40px;
    }
  }
}

.nav-logo.modal-active {
  picture {
    width: 144px;
    height: 22px;

    @include breakpoint(large up) {
      width: 263px;
      height: 40px;
    }
  }
}
</style>
