<template>
  <ul
    v-if="links?.length"
    class="nav-account-links"
  >
    <li
      v-for="(item, index) in links"
      :key="index"
      :class="{'nav-group-separator': item.isSignOut}"
    >
      <Link
        :href="item.url"
        :tracking="item.tracking"
        @click="onClick(item)"
      >
        {{ item.text }}
      </Link>
    </li>
  </ul>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_MYACCOUNT_LINKS } from '../../types/getters';
import Link from '../common/Link';
import useSignOutClick from '../../composables/useSignOutClick';

const store = useStore();
const { isMcom } = store.state.envProps;
const links = computed(() => store.getters[`headerStore/${GET_MYACCOUNT_LINKS}`]);

function onClick(item) {
  if (item.isSignOut) {
    useSignOutClick(isMcom);
  }
}
</script>

<style lang="scss">
@import "../../scss/mixins";

.nav-account-links {
  @include padding-top-s;
  list-style: none;

  li + li {
    margin-top: 20px;
  }

  li + .nav-group-separator {
    @include margin-top-s;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nav-group-separator {
  @include padding-top-s;
  border-top: 1px solid $gray-3-color;
}
</style>
