<template>
  <nav
    ref="navigation"
    class="navigation-rail"
    data-testid="navigation-rail"
  >
    <ul
      id="nav-top-menu"
      class="grid-container grid-x align-justify"
    >
      <li
        class="fob-item hamburger-menu"
        :class="{ 'active': isMenuOpened }"
      >
        <button
          ref="menuTarget"
          aria-label="Open Shop All Menu"
          aria-haspopup="true"
          aria-controls="mobile-nav"
          :aria-expanded="isMenuOpened"
          data-testid="burger-button"
          class="hamburger-menu-btn padding-bottom-xs"
          @click.prevent="toggleNav"
        >
          <i class="menu-large menu-small" />
          <span
            class="menu-link-heavy menu-label"
            :class="fobClass"
          >Shop All</span>
        </button>
      </li>
      <li
        v-for="(fob, index) in fobs"
        :key="fob.id"
        class="fob-item"
      >
        <Link
          :id="`fob-${fob.text}`"
          :href="fob.url"
          :data-id="fob.id"
          :data-tracking="trackingObjToString(fob.tracking)"
          class="menu-link-heavy padding-bottom-xs"
          :class="fobClass"
          :style="fob.color && { color: fob.color }"
          @click="tagNavLinkClick({
            categoryId: fob.id,
            clickableElement: fob.text,
            col: 1,
            pos: index + 1,
            context,
            tracking: fob.tracking
          })"
        >
          {{ fob.text }}
        </Link>
      </li>
    </ul>
    <div
      class="wrapper grid-container grid-x align-justify"
      :class="{ active: isVisibleSideNav }"
      :style="contentWrapStyles"
    >
      <MobileMenu />
    </div>
  </nav>
</template>
<script setup>
import {
  onMounted, computed, ref, defineAsyncComponent,
} from 'vue';
import { useStore } from 'vuex';
import { tagNavLinkClick } from '../../features/analytics/navigationTags';

import Link from '../common/Link';

import useMenu from '../../composables/useMenu';
import { GET_HEADER_DATA } from '../../types/getters';
import { trackingObjToString } from '../../utils/restoreMenuFromDom';

const MobileMenu = defineAsyncComponent(() => import(/* webpackChunkName: "MobileMenu" */ '../header-mobile/MobileMenu.common'));

const store = useStore();

const { context } = store.state.pageData.navigation;
const { menuTarget, isMenuOpened, toggleNav } = useMenu();

const navigation = ref(null);
const headerHeight = ref(0);
const contentWrapStyles = computed(() => ({ height: `calc(100vh - ${headerHeight.value})` }));
const fobs = computed(() => store.getters[`headerStore/${GET_HEADER_DATA}`]?.desktopMenu || []);
const fobClass = computed(() => (fobs.value.length > 12 ? 'fob-small' : ''));
const isVisibleSideNav = computed(() => store.state.navMobileStore.isVisibleSideNav);

function getHeaderHeight() {
  const header = document.getElementById('nav-header-root');
  headerHeight.value = `${header?.offsetHeight || 0}px`;
}

onMounted(() => {
  getHeaderHeight();
});
</script>

<style lang="scss" scoped>
@import "../../scss/mixins/index.scss";

.wrapper {
  position: relative;
  z-index: -1;
  overflow: hidden;

  &.active {
    z-index: auto;
    overflow: visible;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -100%;
      top: 1px;
      height: 100vh;
      width: 100%;
      background-color: $white;
      z-index: 100;
    }
  }
}

.navigation-rail {
  position: relative;
  border-bottom: 1px solid $gray-4-color;
}

.menu-link-heavy {
  display: block;
  border: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.fob-item {
  position: relative;
  @include padding-top-xs;

  &:hover,
  &:focus,
  &.active {
    &::after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $black;
    }
  }
}

.hamburger-menu-btn {
  align-items: center;
  cursor: pointer;
  display: flex;

  i.menu-large {
    position: absolute;
  }

  .menu-label {
    padding-left: 10px;
    margin-left: 28px;
  }
}

// prevent links overlapping for small desktop screens
@media only screen and (max-width: 1120px) {
  .fob-small {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped brand="mcom">
@import "../../scss/icons.mcom.scss";

.navigation-rail {
  height: 53px;
}
</style>

<style lang="scss" scoped brand="bcom">
@import "../../scss/icons.bcom.scss";

.navigation-rail {
  height: 49px;
}

.menu-link-heavy {
  font-size: 12px;
  line-height: 16px;
}

.hamburger-menu-btn {
  .menu-label {
    text-transform: uppercase;
  }
}
</style>
