/* eslint-disable no-shadow */

import { addExperiment } from '../Utils';
import { getURLParam } from '../../../utils/Url';
import { commaSeparatedToArray } from '../../../utils/StringUtils';
import {
  SETUP_CLIENT_SIDE_EXPERIMENTATION, SETUP_SERVER_SIDE_EXPERIMENTATION, SET_EXPERIMENT_ID, GET_EXPERIMENTS, GET_EXPERIMENTS_IDS,
} from '../types';
import { NAV_KILLSWITCH_STORE, NAV_VIEW_TYPE_STORE } from '../../../types/names';
import experiments from '../config';

export function state() {
  return {
    ids: [],
  };
}

export const getters = {
  [GET_EXPERIMENTS](state, _, rootState) {
    const kss = rootState[NAV_KILLSWITCH_STORE].killswitches;
    const recipieOnly = rootState.pageData?.devMode?.queryParams?.recipieOnly;
    const { viewType } = rootState[NAV_VIEW_TYPE_STORE];
    const idsMap = state.ids.reduce((map, cur) => {
      map[cur] = true;
      return map;
    }, {});

    return experiments.reduce((map, { ks, treatment, devices }) => {
      const isKsCheckPassed = kss[ks] || recipieOnly;
      map[ks] = Boolean(idsMap[treatment] && isKsCheckPassed && devices.includes(viewType));
      return map;
    }, {});
  },
  [GET_EXPERIMENTS_IDS](state) {
    return state.ids;
  },
};

export const mutations = {
  [SET_EXPERIMENT_ID](state, ids) {
    state.ids = addExperiment(state.ids, ids);
  },
};

const recipieIdsFromUrl = (queryParam) => commaSeparatedToArray(getURLParam(queryParam));

const getRecipieIds = async () => {
  const overrideRecipieIds = recipieIdsFromUrl('SSC').concat(recipieIdsFromUrl('CSC'));
  const { Experimentation } = await import('@common-vue/util/src/modules/experimentation');
  return Experimentation.getExpIds().concat(overrideRecipieIds);
};

export const actions = {
  [SETUP_SERVER_SIDE_EXPERIMENTATION]({ commit, rootState }) {
    commit(SET_EXPERIMENT_ID, rootState.pageData.experimentation.recipieIds);
  },
  async [SETUP_CLIENT_SIDE_EXPERIMENTATION]({ commit }) {
    const recipieIds = await getRecipieIds();
    commit(SET_EXPERIMENT_ID, recipieIds);
  },
};
