<template>
  <Slideout :id="slideoutId">
    <div class="account-slideout-container">
      <button
        aria-label="close account slideout"
        class="account-slideout-close"
        @click="toggleSlideout"
      >
        <span class="close-large" />
      </button>
      <AccountContent />
    </div>
  </Slideout>
</template>

<script setup>
import { Slideout } from '@atomic-ui/slideout';
import useAccountSlideout from '../../composables/features/useAccountSlideout';
import AccountContent from '../account/AccountContent.common';

const slideoutId = 'account-slideout';
const { init, toggleSlideout } = useAccountSlideout();

init(slideoutId);
</script>

<style lang="scss">
@import "../../scss/mixins";

#account-slideout {
  padding: 0;
  width: 428px;

  .slideout-header {
    display: none;
  }
}

.account-slideout {
  &-close {
    position: absolute;
    right: 48px;
    top: 46px;
    cursor: pointer;
  }

  &-container {
    padding: 0 48px;
    position: relative;
  }
}
</style>
