<template>
  <section
    v-if="data"
    id="nav-deals"
    class="nav-deals"
  >
    <div class="nav-deals-scroller">
      <div class="nav-deals-wrap">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="data.heading"
          class="nav-deals-title"
          v-html="data.heading"
        />
        <!-- eslint-enable -->
        <ul
          v-if="data.deals?.length"
          class="nav-deals-list"
        >
          <li
            v-for="(deal, index) of data.deals"
            :key="index"
          >
            <Link
              :href="deal.value"
              @click="tagDeals(index + 1, getFullText(deal))"
            >
              <b v-if="deal.heading">{{ deal.heading }}</b> <span v-if="deal.body">{{ deal.body }}</span>
            </Link>
          </li>
        </ul>
        <div
          v-if="data.allDealsLink?.value"
          class="nav-deals-all"
        >
          <Link
            :href="data.allDealsLink.value"
            @click="tagDeals(getIndexForAllDeals(), data.allDealsLink.text)"
          >
            <b>{{ data.allDealsLink.text }}</b>
            <i class="chevron-medium-right-black margin-left-xxs" />
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { GET_DEALS_DATA } from '../../types/getters';
import Link from '../common/Link';
import { fireLinkTag } from '../../features/analytics/tagManager';

const store = useStore();
const data = computed(() => store.getters[`headerStore/${GET_DEALS_DATA}`]);

function getFullText(deal) {
  const { heading = '', body = '' } = deal;

  return `${heading} ${body}`.trim();
}

function getIndexForAllDeals() {
  return data.value.deals?.length ? data.value.deals.length + 1 : 1;
}

function tagDeals(index, text = '') {
  const value = `skinny deals banner:cta${index}:${text}`;
  const payload = {
    event_name: value,
    link_name: value,
  };

  fireLinkTag(payload);
}
</script>

<style lang="scss">
@import "../../scss/mixins/index.scss";

$dealsHeight: 40px;

.nav-deals {
  overflow: hidden;
  white-space: nowrap;
  height: $dealsHeight;
  background-color: $black;
  color: $white;

  a {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
    position: relative;
    top: -1px;

    &:hover {
      text-decoration: underline;
    }

    @include breakpoint(medium up) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &-scroller {
    overflow-x: auto;
    padding-bottom: 10px;
    margin-bottom: -10px;
  }

  &-wrap {
    display: flex;
    flex-wrap: nowrap;
    height: $dealsHeight;
    justify-content: space-between;
  }

  &-title {
    font-size: 12px;
    font-weight: 700;
    height: 100%;
    padding: 0 14px 0 20px;
    line-height: $dealsHeight;
    background-color: map-get($foundation-palette, primary);
    position: relative;

    @include breakpoint(medium up) {
      font-size: 14px;
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 12px solid map-get($foundation-palette, primary);
      position: absolute;
      top: 0;
      left: 100%;
    }
  }
}

.nav-deals-list {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 10px;

  li {
    padding: 0 16px 0 17px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 16px;
      position: absolute;
      left: 0;
      background-color: $gray-2-color;
    }

    &:first-child {
      padding-left: 16px;

      &::after {
        display: none;
      }
    }
  }
}

.nav-deals-all {
  padding-left: 16px;
  padding-right: 20px;
  height: 100%;
  display: flex;

  a {
    display: flex;
    align-items: center;
  }

  i {
    filter: invert(100%);
  }
}
</style>
