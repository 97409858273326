module.exports = [
  {
    description: 'for mobile menu redesign',
    control: '2063-20',
    treatment: '2063-21',
    ks: 'enhancedMobileNavEnabled',
    devices: ['Mobile'],
  },
  {
    description: 'for desktop menu redesign',
    control: '2069-20',
    treatment: '2069-21',
    ks: 'enhancedDesktopNavEnabled',
    devices: ['Desktop'],
  },
  {
    description: 'price simplification for best sellers section',
    control: '2054-20',
    treatment: '2054-21',
    ks: 'priceSimplificationEnabled',
    devices: ['Desktop', 'Mobile'],
  },
  {
    description: 'account drawer',
    control: '200-20',
    treatment: '200-21',
    ks: 'accountDropdownEnabled',
    devices: ['Desktop', 'Mobile'],
  },
];
