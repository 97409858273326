<template>
  <div
    id="nav-footer-root"
    @click="popupLinkHandler"
  >
    <BaseFooter
      v-bind="attrs"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';

import { useInitFooter } from '../composables/useInit';
import { useInitExperimentation } from '../features/experimentation/useInit';
import { usePopupLink } from '../composables/features/usePopupLink';
import { GET_ATTRS } from '../types/getters';
import BaseFooter from './BaseFooter';

const store = useStore();
const { popupLinkHandler } = usePopupLink();

const attrs = store.getters[`headerStore/${GET_ATTRS}`];
const { isMinimal = false } = attrs;

useInitExperimentation();

if (!isMinimal) {
  useInitFooter();
}
</script>
