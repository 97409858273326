export const IS_MENU_RESTORATION_FROM_DOM_ENABLED = true;

export function getTrackingObj(value) {
  return value ? JSON.parse(value) : undefined;
}

export function getLinkObj(link) {
  if (!link) {
    return {
      id: undefined,
      text: '',
      url: '',
      tracking: undefined,
    };
  }

  const { id, tracking, url: dataUrl } = link.dataset;
  const url = link.getAttribute('href') || dataUrl || '';

  return {
    url,
    id: id || link.id,
    text: link.textContent || '',
    tracking: url ? getTrackingObj(tracking) : undefined,
  };
}

export function getMobileTopMenu(withOrder) {
  const topCategoryNodes = document.querySelectorAll('.l1-categories a,.l1-categories button');
  return Array.from(topCategoryNodes).reduce((acc, cur, index) => {
    const data = getLinkObj(cur);
    const itemData = {
      ...data, children: [], isCatSplash: Boolean(cur.dataset.catSplash),
    };

    if (withOrder) {
      itemData.order = index;
    }

    acc[data.id] = itemData;

    return acc;
  }, {});
}

export function getMobileTopMenuWithGroups(withOrder) {
  const topMenu = {};
  const categoryNodes = document.getElementsByClassName('l1-categories-group');

  Array.from(categoryNodes).forEach((group) => {
    const linkNodes = group.querySelectorAll('.l1-categories a,.l1-categories button');

    Array.from(linkNodes).forEach((cur, index) => {
      const data = getLinkObj(cur);
      const itemData = {
        ...data, children: [], isCatSplash: Boolean(cur.dataset.catSplash),
      };

      if (withOrder) {
        itemData.order = index;
      }

      topMenu[data.id] = itemData;
    }, {});
  });

  return topMenu;
}

export function getMobileDeepMenu(topMenu = {}, withOrder) {
  return Array.from(document.querySelectorAll('.sub-categories')).reduce((acc, subCategory) => {
    const {
      id, parent: parentId, text, url, tracking, catSplash: isCatSplash,
    } = subCategory.dataset;
    const links = subCategory.querySelectorAll('li a,li button');

    if (id === 'LOYALLIST') {
      return acc;
    }

    const itemData = {
      id,
      text,
      url,
      ...(parentId ? { parentId } : {}),
      isCatSplash: Boolean(isCatSplash),
      tracking: getTrackingObj(tracking),
      children: Array.from(links).reduce((childrenAcc, cur) => {
        if (cur.classList.contains('l3-link')) {
          return childrenAcc;
        }
        if (!cur.dataset.skip) {
          childrenAcc.push({ ...getLinkObj(cur), isCatSplash: Boolean(cur.dataset.catSplash) });
        }
        return childrenAcc;
      }, []),
    };

    const isTopLevel = !parentId;

    if (isTopLevel && withOrder) {
      itemData.order = topMenu[id]?.order;
    }

    acc[id] = itemData;

    return acc;
  }, {});
}

export function restoreMobileMenu(withOrder, withGroups) {
  const topMenu = withGroups ? getMobileTopMenuWithGroups(withOrder) : getMobileTopMenu(withOrder);
  return { ...topMenu, ...getMobileDeepMenu(topMenu, withOrder) };
}

function getLinkColor(link) {
  return link?.getAttribute('style')?.match(/color:\s?(#[a-z0-9]{3,6})/i)?.[1] || '';
}

export function parseDesktopTopLevel() {
  const root = document.getElementById('nav-top-menu');
  return Array.from(root.children).reduce((acc, li) => {
    const link = li.querySelector('a');

    if (link) {
      const color = getLinkColor(link);
      acc.push({
        ...getLinkObj(link),
        color,
      });
    }

    return acc;
  }, []);
}

export function parseDesktopFobLevel(text) {
  const root = document.querySelector(`[data-testid="flyout-${text}"]`);
  const columns = root.getElementsByClassName('category-cell');

  return Array.from(columns).map((col) => Array.from(col.getElementsByClassName('category-group')).map((group) => {
    if (group.classList.contains('flyout-image-wrapper')) {
      const image = group.querySelector('img');
      const linkUnderImage = group.querySelector('.menu-link-heavy');
      const imageLink = group.querySelector('.image-link');
      const underImageLink = group.querySelector('.under-image-link');

      return {
        isImage: true,
        src: image.dataset.src,
        imageLink: group.querySelector('.image-link').getAttribute('href'),
        text: linkUnderImage?.textContent || '',
        url: linkUnderImage?.getAttribute('href') || '',
        alt: image.alt || undefined,
        imageLinkTracking: getTrackingObj(imageLink?.dataset?.tracking),
        linkTracking: getTrackingObj(underImageLink?.dataset?.tracking),
      };
    }

    const headerLink = group.querySelector('.subcategory-header-link,h5');
    const headerLinkObj = getLinkObj(headerLink);
    return {
      ...headerLinkObj,
      color: getLinkColor(headerLink),
      id: group.dataset.id,
      children: Array.from(group.querySelectorAll('.menu-link-sm.link')).map((link) => ({
        ...getLinkObj(link),
        color: getLinkColor(link),
      })),
    };
  }));
}

export function restoreDesktopMenu(withOrder, isExperiment) {
  const desktopMenu = parseDesktopTopLevel();

  if (isExperiment) {
    const menu = restoreMobileMenu(withOrder, isExperiment);
    return { desktopMenu, menu };
  }

  // parse flyouts
  desktopMenu.forEach((category) => {
    category.children = parseDesktopFobLevel(category.text);
  });

  return { desktopMenu };
}

export function trackingObjToString(tracking) {
  return typeof tracking === 'object' ? JSON.stringify(tracking) : undefined;
}
