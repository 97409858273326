import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import useScrollLock from './ui/useScrollLock';
import { useMobileNav } from './useMobileNav';
import { onMenuOpen, onMenuClose } from '../features/analytics/mobileMenuTags';
import {
  OPEN_MENU,
  CLOSE_MENU,
  SET_ACTIVE_OVERLAY_NAME,
  RESET_OVERLAY,
  CLEAR_CONTENT_STACK,
} from '../types/mutations';
import { SET_NAV_CONTENT } from '../types/actions';
import { MOBILE_NAVIGATION_OVERLAY } from '../constants/common/overlayNames';

const isMenuOpened = ref(false);
const menuTarget = ref(null);

export default function useMenu() {
  const store = useStore();
  const isVisibleSideNav = computed(() => store.state.navMobileStore.isVisibleSideNav);
  const { reset: resetMobileNav } = useMobileNav(store);
  const { lock, unlock } = useScrollLock();

  function clearNavStack() {
    store.commit(`navMobileStore/${CLEAR_CONTENT_STACK}`);
    store.dispatch(`navMobileStore/${SET_NAV_CONTENT}`, { id: 'topMenu' });
  }

  function openNav() {
    lock();
    isMenuOpened.value = true;
    onMenuOpen();
    clearNavStack();
    store.commit(`navMobileStore/${OPEN_MENU}`);
    store.commit(`navOverlay/${SET_ACTIVE_OVERLAY_NAME}`, MOBILE_NAVIGATION_OVERLAY);
  }

  function closeNav() {
    unlock();
    isMenuOpened.value = false;
    onMenuClose();
    clearNavStack();
    resetMobileNav();
    store.commit(`navMobileStore/${CLOSE_MENU}`);
    store.commit(`navOverlay/${RESET_OVERLAY}`);
    menuTarget.value?.focus();
  }

  function toggleNav() {
    if (isVisibleSideNav.value) {
      closeNav();
    } else {
      menuTarget.value?.blur();
      openNav();
    }
  }

  return {
    menuTarget, isMenuOpened, toggleNav, openNav, closeNav, clearNavStack,
  };
}
