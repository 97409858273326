<template>
  <div
    v-if="prosProducts.length"
    class="product-recommendations-wrapper"
  >
    <h4 class="cell small-6 margin-bottom-m">
      Best Sellers
    </h4>
    <div
      class="products-container"
      data-testid="product-recommendations"
      @click="applyCmParams"
    >
      <ProductThumbnail
        v-for="product in prosProducts"
        :key="product.id"
        :product="product.product"
        :hide-review-text-and-rating="true"
        :disable-slideshow="true"
        :image-width="112"
        :wrap-product-name-and-brand="true"
        :is-simplification-mcom-on="experiments.priceSimplificationEnabled"
      />
    </div>
  </div>
</template>

<script setup>
/* istanbul ignore file */
import { watch, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { ProductThumbnail } from '@common-vue/product-thumbnail';
import { useSessionStorage } from '@vueuse/core';

import { useProsFetch } from '../../composables/dumb/useProsFetch';
import { deleteReviews, parseBrandAndName } from '../../utils/formatProduct';
import { addQueryParamsToUrl } from '../../utils/Url';
import useExperiments from '../../composables/useExperiments';

const experiments = useExperiments();
const store = useStore();
const { hostlink } = store.state.envProps;
const searchValue = computed(() => store.state.headerStore.searchValue);
const suggestion = computed(() => store.state.navSearchSuggestionsStore.topSuggestion);
const prosProducts = ref([]);

watch(suggestion, async () => {
  if (suggestion.value) {
    const cached = useSessionStorage(`pros-${suggestion.value}`, []);
    if (cached.value.length) {
      prosProducts.value = cached.value;
      return;
    }

    const { fetch, result } = useProsFetch({ phrase: suggestion.value, hostlink });
    await fetch();

    if (result.value) {
      prosProducts.value = result.value.map(deleteReviews).map(parseBrandAndName);
      cached.value = prosProducts.value;
    }
  } else {
    prosProducts.value = [];
  }
},
// Immediate watcher invokation is necessary so that prosProducts/best sellers gets
// repopulated with cached data if searchModal opens again, instead of being an empty array
{ immediate: true });

const applyCmParams = (event) => {
  const link = event.target.closest('a');

  if (link) {
    event.preventDefault();
    const url = link.getAttribute('href');

    const $firstFromLastSearches = document.querySelector('.search-suggestions .suggestion');
    const firstFromLastSearchesText = $firstFromLastSearches?.getAttribute('data-label');
    const cmParams = {
      ackws: searchValue.value,
      kws: firstFromLastSearchesText,
    };

    const urlWithParams = addQueryParamsToUrl(url, cmParams);

    window.location.assign(urlWithParams);
  }
};
</script>

<style scoped lang="scss" brand="mcom">
@import '../../scss/mixins.bcom.scss';

.products-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.product-thumbnail-container {
  max-width: 112px;
}

.product-recommendations-wrapper {
  display: none;
  min-height: 400px;
}

.product-recommendations-wrapper :deep(.v-product-thumbnail-image-container) {
  container-type: normal;
}

@include breakpoint(large up) {
  .product-recommendations-wrapper {
    display: block;
    padding: 0;
    max-width: 628px;
    @include margin-right-l;
    @include margin-left-l;
    @include margin-top-xl;
  }
}
</style>
