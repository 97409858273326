import { useStore } from 'vuex';

/* eslint-disable import/prefer-default-export */
export function useQueryParams() {
  const store = useStore();

  // for server side rendering
  if (typeof window === 'undefined') {
    return store.state.pageData.navigation.queryParams;
  }

  // for client side rendering
  const searchParams = new URLSearchParams(window.location.search);

  return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
}
