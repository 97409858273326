<template>
  <nav
    ref="navigation"
    class="navigation-rail"
    data-testid="navigation-rail"
    @mouseleave="close"
  >
    <ul
      id="nav-top-menu"
      class="grid-container grid-x align-justify"
    >
      <li
        v-for="(fob, index) in fobs"
        :key="fob.id"
        class="fob-item"
        :class="{ 'active': isActive(fob.text) }"
        @mouseover="onMouseOver(fob)"
        @mouseleave="onMouseLeave"
      >
        <Link
          :id="`fob-${fob.text}`"
          :href="fob.url"
          :data-id="fob.id"
          :data-tracking="trackingObjToString(fob.tracking)"
          class="menu-link-heavy"
          :style="fob.color && { color: fob.color }"
          @click="tagNavLinkClick({
            categoryId: fob.id,
            clickableElement: fob.text,
            col: 1,
            pos: index + 1,
            context,
            tracking: fob.tracking
          })"
          @keydown.arrow-left.prevent="onKeydownArrowLeft(navigation)"
          @keydown.arrow-right.prevent="onKeydownArrowRight(navigation)"
        >
          {{ fob.text }}
        </Link>

        <button
          class="chevron-button"
          :class="{ 'active': isActive(fob.text) }"
          :aria-expanded="isActive(fob.text)"
          :aria-controls="fob.text"
          :aria-label="`Open ${fob.text} menu`"
          @keydown.tab="close"
          @keydown.esc.prevent="close"
          @keydown.arrow-up.prevent="close"
          @keydown.enter.prevent="onToggleVisibility(fob)"
          @keydown.space.prevent="onToggleVisibility(fob)"
          @keydown.arrow-down.prevent="onToggleVisibility(fob)"
          @keydown.arrow-left.prevent="onKeydownArrowLeft(navigation)"
          @keydown.arrow-right.prevent="onKeydownArrowRight(navigation)"
        >
          <span class="chevron-small-down" />
        </button>
      </li>
    </ul>
    <Flyouts
      :flyout-id="activeFobId"
      @close="closeAndMoveFocusToCurrentFob"
    />
  </nav>
</template>
<script setup>
/* istanbul ignore file */
import {
  computed, watch, ref,
} from 'vue';
import { useStore } from 'vuex';

import Link from '../common/Link';
import Flyouts from './Flyouts.common';

import { useDesktopNavigation } from '../../composables/accessibility/useDesktopNavigation';
import { useOverlay } from '../../composables/ui/useOverlay';

import { tagNavLinkClick } from '../../features/analytics/navigationTags';
import { useHeaderMenuData } from '../../composables/useHeaderMenuData';
import { trackingObjToString } from '../../utils/restoreMenuFromDom';

const navigation = ref(null);

const store = useStore();
const overlay = useOverlay('NAVIGATION_OVERLAY');
const {
  activeFobId,
  open,
  close,
  isActive,
  onToggleVisibility,
  onKeydownArrowLeft,
  onKeydownArrowRight,
  moveFocusToCurrentFob,
} = useDesktopNavigation();
const { getMenuData } = useHeaderMenuData(store);

const { context } = store.state.pageData.navigation;
const fobs = computed(() => getMenuData());
const hoveredFobId = ref();

function closeAndMoveFocusToCurrentFob() {
  moveFocusToCurrentFob();
  close();
}

function onMouseOver(fob) {
  hoveredFobId.value = fob.id;

  setTimeout(() => {
    if (hoveredFobId.value === fob.id) {
      open(fob);
    }
  }, 200);
}

function onMouseLeave() {
  hoveredFobId.value = null;
}

watch(activeFobId, (value) => {
  if (value) {
    overlay.show();
  } else {
    overlay.hide();
  }
});
</script>

<style lang="scss" scoped>
@import "../../scss/mixins/index.scss";

.navigation-rail {
  position: relative;
  border-bottom: 1px solid $gray-4-color;
}

.menu-link-heavy {
  display: block;
  border: none;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.fob-item {
  position: relative;
  @include padding-bottom-xs;
  @include padding-top-xs;

  &:hover,
  &:focus,
  &.active {
    &::after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $black;
    }
  }
}

.chevron-button {
  position: absolute;
  bottom: 0;
  height: 16px;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;

  &:focus {
    opacity: 1;
    pointer-events: auto;
  }

  &.active {
    .chevron-small-down {
      transform: rotate(180deg);
    }
  }

  .chevron-small-down {
    position: absolute;
    left: calc(50% - 12px);
  }
}
</style>

<style lang="scss" scoped brand="mcom">
@import "../../scss/icons.mcom.scss";

.navigation-rail {
  height: 53px;
}

.chevron-button {
  height: 14px;

  .chevron-small-down {
    @extend .chevron-small-down-black;
    top: -5px;
  }
}
</style>

<style lang="scss" scoped brand="bcom">
@import "../../scss/icons.bcom.scss";

.navigation-rail {
  height: 49px;
}

.chevron-button {
  height: 16px;

  .chevron-small-down {
    @extend .chevron-small-down;
    top: -4px;
  }
}

.menu-link-heavy {
  font-size: 12px;
  line-height: 16px;
}
</style>
