import Links from '../../constants/Links';
import { viewTypes } from '../../constants';
import { localKillswitches } from '../../features/killswitches/config';
import AccountLinks from '../../constants/AccountLinks';
import transformEditorPopupLink from '../../utils/transformEditorPopupLink';
import experiments from '../../features/experimentation/config';

export function isExperimentActive(rootState, expName) {
  const exp = experiments.find((i) => i.ks === expName) || null;
  return exp ? rootState.navExperimentationStore.ids.includes(exp.treatment) : false;
}

export const remove = (links, textsToRemove) => {
  const normalizedTexts = textsToRemove.map((text) => text.toLowerCase());
  return links.filter((link) => !normalizedTexts.includes(link.text.toLowerCase().trim()));
};

export function getColorFromAttributes(attributes, prop) {
  if (!attributes) {
    return '';
  }

  const defaultColors = ['#000', '#000000'];
  const color = attributes.find(({ name }) => name === prop)?.value;

  if (!color) {
    return '';
  }

  return defaultColors.includes(color) ? '' : color;
}

export function getFobColumnGroupData({ contentLinks: { value, text, semanticUrl, tracking } }, index, idPrefix, linkColor) { // eslint-disable-line
  return {
    text,
    tracking,
    id: `${idPrefix}_${index}`, // we cannot use value as an ID because value can contain url instead of ID, and url can be not unique
    url: semanticUrl || value,
    color: linkColor,
  };
}

export function getFobColumnData({ contents }, groupIndex, idPrefix) {
  return contents.map((column, index) => {
    const { flexibleLinks, navigationFlyoutAd } = column;
    const { attributes } = flexibleLinks || {};
    const subCategoryColor = getColorFromAttributes(attributes, 'categoryFontColor');
    const linkColor = getColorFromAttributes(attributes, 'subCategoryFontColor');
    const newIdPrefix = `${idPrefix}_${groupIndex}_${index}`;

    if (navigationFlyoutAd) {
      const adLink = navigationFlyoutAd.contentlinks?.[0];
      const imageLink = navigationFlyoutAd.image?.contentlinks?.[0];
      const url = adLink?.semanticUrl || adLink?.value || '';

      return {
        url,
        isImage: true,
        src: `${navigationFlyoutAd.urlTemplate}${navigationFlyoutAd.urlTemplate.endsWith('/') ? '' : '/'}${navigationFlyoutAd.image.fileName}`,
        imageLink: imageLink?.semanticUrl || imageLink?.value || '',
        imageLinkTracking: imageLink?.tracking || undefined,
        text: adLink?.text || '',
        alt: navigationFlyoutAd.body || adLink?.text,
        linkTracking: adLink?.tracking || undefined,
      };
    }

    const groupLink = flexibleLinks.category?.[0]?.contentLinks || {};

    groupLink.text = groupLink.text || '';
    groupLink.id = groupLink.value || newIdPrefix;

    const url = groupLink.semanticUrl || groupLink.value || '';

    return {
      url,
      id: groupLink.id,
      text: groupLink.text,
      tracking: url ? groupLink.tracking : undefined,
      color: subCategoryColor,
      children: flexibleLinks.subCategory.map((item, i) => getFobColumnGroupData(item, i, newIdPrefix, linkColor)),
    };
  });
}

export function getMobileFobColumnData(column, groupIndex, idPrefix) {
  const { contentLinks, flexibleLinks } = column;
  const groupLink = contentLinks || flexibleLinks?.category?.[0]?.contentLinks || { text: `NO_NAME_${groupIndex}` };
  const newIdPrefix = `${idPrefix}_${groupIndex}`;
  const id = contentLinks?.type === 'CATEGORY' ? contentLinks.value : newIdPrefix;

  return {
    id,
    tracking: groupLink.tracking,
    realId: groupLink.value,
    text: groupLink.text,
    url: groupLink.semanticUrl || groupLink.value || '',
    children: flexibleLinks?.subCategory ? flexibleLinks?.subCategory?.map((item, i) => getMobileFobColumnData(item, i, newIdPrefix)) : [],
  };
}

export function menuAdapter(data, isMobile) {
  if (!data.header.fobs) return [];
  return data.header.fobs.globalNavigation.reduce((acc, fob, index) => {
    const idPrefix = index.toString();
    const { contentlinks, attributes, flyout = {} } = fob;
    const mainLink = contentlinks?.[0] || { text: `NO_NAME_${idPrefix}` };
    const { container } = flyout;
    const color = getColorFromAttributes(attributes, 'fontColor');
    let children;

    if (!container) {
      children = [];
    } else if (isMobile) {
      children = container[0].contents[0].flexibleLinks.subCategory.map((item, i) => getMobileFobColumnData(item, i, idPrefix));
    } else {
      children = container.map((item, i) => getFobColumnData(item, i, idPrefix));
    }

    const mainLinkUrl = mainLink.semanticUrl || mainLink.value || '';

    if (!mainLinkUrl && !children.length) {
      return acc;
    }

    mainLink.id = mainLink.value || idPrefix;
    acc.push({
      id: mainLink.id,
      tracking: mainLink.tracking,
      text: mainLink.text,
      url: mainLinkUrl,
      ...(isMobile ? {} : { color }), // do not pass color for mobile as of now
      children,
    });

    return acc;
  }, []);
}

export function getServiceLinks(data) {
  const id = 'SITE_SERVICE_LINKS';
  return {
    id,
    name: id,
    items: [{
      children: data.header.secondaryNavigation?.contentlinks.map(({ text, value: url, tracking }) => ({ text, url, tracking })) || [],
    }],
  };
}

export function getMcomMobileTopCategoryIds(properties, isExperiment) {
  if (isExperiment) {
    const ids = [];

    properties.menuLabels.forEach((label) => {
      ids.push(...properties[label]);
    });

    return ids;
  }

  return properties.topMenu.split(',');
}

export function transformBcomMobileMenu(map, categories, parentId) {
  categories.forEach(({
    id, text, url, tracking, children,
  }, index) => {
    const normalizedChildren = (children[0]?.group || children).reduce((acc, link) => {
      const subLinks = link.children?.[0]?.group;

      if (subLinks) {
        transformBcomMobileMenu(map, [link], id);
      } else if (!link.url) {
        return acc;
      }

      acc.push({
        id: link.id,
        text: link.text,
        url: link.url || '',
        tracking: link.tracking,
        isCatSplash: false,
      });

      return acc;
    }, []);

    const itemData = {
      id,
      text,
      tracking,
      url: url || '',
      children: normalizedChildren,
      isCatSplash: false,
    };

    const isTopLevel = !parentId;

    if (isTopLevel) {
      itemData.order = index;
    }

    if (parentId) {
      itemData.parentId = parentId;
    }

    map[id] = itemData;
  });

  return map;
}

function parseMcomMobileMenu(menu, topMenuIds) {
  const menuObj = menu || {};
  const topMenuIdsMap = topMenuIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});
  return Object.values(menuObj).reduce((acc, {
    id, text, tracking, url, parent, children, categoryPageType,
  }) => {
    if (topMenuIdsMap[id]) {
      if (!url && !children) {
        return acc;
      }
    } else if (!children) {
      return acc;
    }

    acc[id] = {
      id,
      text,
      tracking,
      url: url || '',
      isCatSplash: Boolean(categoryPageType === 'Category Splash' && children?.length),
      children: (children || []).reduce((childrenAcc, link) => {
        if (!link.url && !menuObj[link.id]?.children?.length) {
          return childrenAcc;
        }

        childrenAcc.push({
          id: link.id,
          text: link.text,
          url: link.url || '',
          tracking: link.tracking,
          isCatSplash: Boolean(link.categoryPageType === 'Category Splash' && menu[link.id]?.children?.length),
        });

        return childrenAcc;
      }, []),
    };

    if (parent?.id) {
      acc[id].parentId = parent.id;
    }

    return acc;
  }, {});
}

export function parseAndReduceMobileMenu(menu, properties, isMcom, isExperiment) {
  const reducedMobileMenu = {};
  const categoryLevels = {};
  let topCategories;
  let mobileMenu;

  if (isMcom) {
    topCategories = getMcomMobileTopCategoryIds(properties, isExperiment);
    mobileMenu = parseMcomMobileMenu(menu || {}, topCategories);
  } else {
    topCategories = isExperiment ? getMcomMobileTopCategoryIds(properties, isExperiment) : menu.map(({ id }) => id);
    mobileMenu = transformBcomMobileMenu({}, menu || []);
  }

  if (!isExperiment) {
    return mobileMenu;
  }

  topCategories.forEach((id) => {
    const category = mobileMenu[id];

    if (!category) {
      return;
    }

    reducedMobileMenu[id] = category;
    (categoryLevels[id] || (categoryLevels[id] = {})).l1 = true;
    category?.children?.forEach(({ id: subCategoryId }) => {
      const subCategory = mobileMenu[subCategoryId];

      if (subCategory) {
        reducedMobileMenu[subCategoryId] = subCategory;
        (categoryLevels[subCategoryId] || (categoryLevels[subCategoryId] = {})).l2 = true;
      }
    });
  });
  // remove redundant empty links from children
  Object.values(reducedMobileMenu).forEach((category) => {
    if (category?.children?.length) {
      category.children = category.children.filter((link) => link.url || categoryLevels[link.id]?.l1 || categoryLevels[link.id]?.l2, []);
    }
  });

  return reducedMobileMenu;
}

export function parseEnhancedDesktopMenu(data, isMcom) {
  const dataVerticalMenu = data.header?.fobs?.globalNavigation?.[0]?.verticalNavigation?.menu;
  const dataHorizontalMenu = (data.header?.fobs?.globalNavigation?.[0]?.horizontalNavigation?.[0]?.contentlinks || []);
  const mobileMenu = parseAndReduceMobileMenu(dataVerticalMenu, data.header.meta.properties, isMcom, true);
  const desktopMenu = dataHorizontalMenu.map(({
    value, text, semanticUrl, tracking,
  }, index) => {
    /*
     data setup can be different for category and static links.
     e.g. static link: {
       value: 'https://www.macys.com/show/shoes?id=123',
       text: 'Shoes',
       tracking: { cm_sp: 'param' },
     }
     category link: {
       value: 123,
       text: 'Shoes',
       semanticUrl: 'https://www.macys.com/show/shoes?id=123',
       tracking: { cm_sp: 'param' },
     }
      */
    if (semanticUrl) {
      return {
        text,
        tracking,
        id: value,
        url: semanticUrl,
      };
    }

    return {
      text,
      tracking,
      id: `desktop-menu-${index}`,
      url: value || '',
    };
  });

  return { mobileMenu, desktopMenu };
}

export function parseAEMData(source, rootState, isAemMenu) {
  const data = { ...source };
  const { viewType } = rootState.navViewTypeStore;
  const { isMcom } = rootState.envProps;
  const isMobile = viewType === viewTypes.mobile;
  const accountLinks = [...remove(data.header?.signInLinks?.contentlinks || [], ['Sign In'])];
  const enhancedDesktopNavExpEnabled = isExperimentActive(rootState, 'enhancedDesktopNavEnabled');
  const enhancedMobileNavExpEnabled = isExperimentActive(rootState, 'enhancedMobileNavEnabled');
  const isEnhancedDesktopNavExp = viewType === viewTypes.desktop
    && enhancedDesktopNavExpEnabled
    && data.header.meta.properties.enhancedDesktopNavEnabled;

  data.media = {
    SITE_MYACCOUNT_MENU: {
      name: 'SITE_MYACCOUNT_MENU',
      items: [{
        children: accountLinks.map(({ value: url, text, tracking }) => ({ url, text, tracking })),
      }],
    },
  };

  if (isMcom) {
    data.topNavigation = data.header.topNavigation;
    data.skinnyDealsBanner = data.header.skinnyDealsBanner;
  } else {
    data.topNavigation = data.header.topNavigation?.map(({ text }) => ({
      text: text && transformEditorPopupLink(text),
    }));
  }
  data.meta = data.header.meta;

  if (isEnhancedDesktopNavExp) {
    const { mobileMenu, desktopMenu } = parseEnhancedDesktopMenu(data, isMcom);
    data.mobileMenu = mobileMenu;
    data.desktopMenu = desktopMenu;
  } else if (isAemMenu) {
    data.menu = isMobile ? transformBcomMobileMenu({}, menuAdapter(data, isMobile)) : menuAdapter(data, isMobile);
  } else if (isMobile) {
    data.menu = parseAndReduceMobileMenu(data.header.menu, data.header.meta.properties, isMcom, enhancedMobileNavExpEnabled);
  }

  if (isMobile || isEnhancedDesktopNavExp) {
    data.media.SITE_SERVICE_LINKS = getServiceLinks(data);
  }

  delete data.header;

  return data;
}

/**
 *
 * @typedef {Object} GroupItem
 * @property {string} id
 * @property {string} text
 * @property {string} [url]
 * @property {Group[]} [children]
 */

/**
 * @typedef {Object} Group
 * @property {GroupItem[]} group
 */

/**
 * @typedef {Object} Fob
 * @property {string} id
 * @property {Group[]} children
 */

/**
 * @typedef {Object} Header
 * @property {Object} meta
 * @property {Object} media
 * @property {Fob[]} menu
 */

/**
 * @typedef {Object} ReactiveHeader
 * @property {Header} value
 */

export function fixSiteServiceGlobalPool(data, rootState) {
  // Experiments
  const enhancedDesktopNavEnabled = isExperimentActive(rootState, 'enhancedDesktopNavEnabled');

  const { isMcom } = rootState.envProps;
  const internationalLink = { internationalLink: true };
  // Forcing Mobile AEM data
  const { viewType } = rootState.navViewTypeStore;
  const isPhone = viewType === viewTypes.mobile;
  const siteServiceLinks = data?.media?.SITE_SERVICE_LINKS?.items[0]?.children || [];
  const siteMyAccountMenu = data?.media?.SITE_MYACCOUNT_MENU?.items[0]?.children || [];

  // Features
  const enhancedDesktopNavFeature = (enhancedDesktopNavEnabled && viewType === 'Desktop');

  if (isPhone) {
    const { isDomestic, isInternational } = rootState.pageData.navigation.context;

    if (isDomestic) {
      const starRewardsData = siteMyAccountMenu.find((i) => i.text === 'Star Rewards') || {};
      const starRewards = (enhancedDesktopNavFeature && isMcom)
        ? [{ ...starRewardsData, text: 'Earn Star Rewards' }]
        : [];
      const weddingRegistry = { text: 'Gift Registry', ...Links.homepage.registry, isRegistrySection: true };
      const additionalLinks = [...starRewards, weddingRegistry];
      const linksToRemove = ['Wedding Registry', 'Gift Registry'];

      return [...additionalLinks, ...remove(siteServiceLinks, linksToRemove)];
    }

    if (isInternational) {
      const additionalLinks = [];
      const linksToRemove = ['Shipping To'];

      return [...additionalLinks, ...remove(siteServiceLinks, linksToRemove), internationalLink];
    }
  }

  return siteServiceLinks;
}

export function addSignedInState(data, rootState) {
  const { isSignedIn } = rootState.navUser.data;

  const { SIGNIN_TEXT } = AccountLinks;
  const { SIGNOUT_TEXT } = AccountLinks;

  const signInLink = {
    text: SIGNIN_TEXT, burstCache: true, isSignIn: true, ...Links.account.signIn,
  };
  const signOutLink = {
    text: SIGNOUT_TEXT, burstCache: true, isSignOut: true, ...Links.account.signOut,
  };

  return isSignedIn ? [...data, signOutLink] : [signInLink, ...data];
}

export function fixMyAccountLinks(data, rootState) {
  const { viewType } = rootState.navViewTypeStore;
  const isDesktop = viewType === viewTypes.desktop;

  const links = data?.media?.SITE_MYACCOUNT_MENU?.items[0]?.children || [];

  if (isDesktop) {
    return [...remove(links, ['star rewards'])];
  }

  const mapToApplyLoyallistId = (link) => (link?.text.toLowerCase() === 'loyallist' ? { ...link, id: 'LOYALLIST' } : link);

  return links.map(mapToApplyLoyallistId);
}

// un-used functions
//
// export function addMyAccountLinksToMedia(data) {
//   const accountMenuText = { text: 'My Account' };
//   if (data.value.media) data.value.media.SITE_MYACCOUNT_MENU = accountMenuText;
//   else data.value.media = { SITE_MYACCOUNT_MENU: accountMenuText };
// }

// export function addLoyalistLinksToMedia(data) {
//   const accountMenuText = { text: 'Loyallist' };
//   if (data.value.media) data.value.media.LOYALLIST = accountMenuText;
//   else data.value.media = { LOYALLIST: accountMenuText };
// }

export function getKillswitchConfig(xapiResponse) {
  return { killswitches: xapiResponse.meta.properties, localKillswitches };
}
