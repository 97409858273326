import { ref } from 'vue';
import { useStore } from 'vuex';

const slideoutId = ref('');

export default function useInternationalSlideout() {
  const store = useStore();

  function init(id) {
    slideoutId.value = id;
  }

  function toggleSlideout() {
    store.commit('slideoutStore/TOGGLE', slideoutId.value);
  }

  function openAccountSlideout() {
    toggleSlideout();
  }

  return {
    slideoutId,
    init,
    openAccountSlideout,
    toggleSlideout,
  };
}
