<template>
  <template
    v-for="fob in fobs"
    :key="fob.text"
  >
    <Flyout
      v-if="fob.children.length"
      :flyout-id="fob.text"
      :is-active="fob.text === currentFob?.text"
      @close="close"
    />
  </template>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import Flyout from './Flyout.common';
import { useHeaderMenuData } from '../../composables/useHeaderMenuData';

const emit = defineEmits(['close']); // eslint-disable-line no-undef

const props = defineProps({ // eslint-disable-line no-undef
  flyoutId: {
    type: String,
    required: true,
  },
});

const store = useStore();
const { getMenuData } = useHeaderMenuData(store);
const fobs = computed(() => getMenuData());
const currentFob = computed(() => fobs.value.find((fob) => fob.text === props.flyoutId));

function close() {
  emit('close');
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" brand="mcom" scoped>
</style>

<style lang="scss" brand="bcom" scoped>
</style>
