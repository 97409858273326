import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { registryLinkGroups } from 'config';
import useFetch from './dumb/useFetch';
import useProxy from './dumb/useProxy';
import { post, put } from '../utils/api';
import { NAV_REGISTRY_STORE } from '../types/names';
import { SET_REGISTRIES } from '../types/mutations';
import { logError } from '../utils/Logger';
import { httpUpdateAPIHeaders } from '../utils/registryUtils';
import { goToUrl } from '../utils/Url';
import useKillswitches from './useKillswitches';
import useSignInClick from './useSignInClick';
import onPropertiesReady from '../events/onPropertiesReady';

let isUpdateListener = false;

export function useRegistry(config = {}) {
  const { checkUpdates = false } = config;
  const store = useStore();
  const { isMcom, hostlink } = store.state.envProps;
  const orgId = isMcom ? 12 : 13;
  const proxy = useProxy(hostlink);
  const killswitches = useKillswitches();
  const isRegistryDropdown = computed(() => killswitches.value.registryDropdownEnabled);
  const defaultLinks = {
    redirectUrl: `${hostlink}/registry/manage`,
    signinUrl: `${hostlink}/account/signin`,
  };

  async function fetchRegistries() {
    if (!isRegistryDropdown.value) return;

    const { uid: userId, guid: userGUID, isSignedIn } = store.state.navUser.data;

    if (!isSignedIn) return;

    const isCallMade = sessionStorage.getItem('registryFetch');

    if (isCallMade) {
      const storedData = localStorage.getItem('userRegistries');

      if (storedData) {
        const registries = JSON.parse(storedData);
        store.commit(`${NAV_REGISTRY_STORE}/${SET_REGISTRIES}`, registries);
        return;
      }
    }

    onPropertiesReady(async () => {
      const apiUrl = '/regsvc/registry/api/v1/registry/association';
      const payload = { userId, userGUID, orgId };
      const params = {
        headers: httpUpdateAPIHeaders(store.state.envProps, payload, killswitches.value),
      };
      const { fetch, result } = useFetch(() => post(proxy(apiUrl), payload, params));

      try {
        await fetch();
      } catch (e) {
        logError(e?.message);
      }

      if (!result.value) return;

      const { registryInfo } = result.value;
      localStorage.setItem('userRegistries', JSON.stringify(registryInfo));
      sessionStorage.setItem('registryFetch', true);
      store.commit(`${NAV_REGISTRY_STORE}/${SET_REGISTRIES}`, registryInfo);
    }, store);
  }

  async function setDefaultRegistry(registryGUID) {
    onPropertiesReady(async () => {
      const payload = { registryGUID, orgId };
      const params = {
        headers: httpUpdateAPIHeaders(store.state.envProps, payload, killswitches.value),
      };
      const apiUrl = '/regsvc/registry/api/v1/registry/defaultRegistry';
      const { fetch, result } = useFetch(() => put(proxy(apiUrl), payload, params));

      try {
        await fetch();
      } catch (e) {
        logError(e?.message);
      }

      if (result.value) {
        goToUrl(defaultLinks.redirectUrl);
      } else {
        useSignInClick();
        sessionStorage.removeItem('registryFetch');
        goToUrl(defaultLinks.signinUrl);
      }
    }, store);
  }

  function setSignInRedirectParams() {
    useSignInClick({ redirectUrl: defaultLinks.redirectUrl });
  }

  onMounted(() => {
    if (checkUpdates && !isUpdateListener) {
      isUpdateListener = true;
      window.addEventListener('onRegistryUpdate', () => {
        sessionStorage.removeItem('registryFetch');
        fetchRegistries();
      });
    }
  });

  return {
    fetchRegistries, setDefaultRegistry, defaultLinks, setSignInRedirectParams,
  };
}

export function useRegistryDropdown() {
  const store = useStore();
  const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
  const hasRegestries = computed(() => store.getters['navRegistryStore/hasRegestries']);
  const occasions = computed(() => store.getters['navRegistryStore/occasions']);
  const allowedOccasions = computed(() => store.getters['navRegistryStore/allowedOccasions']);
  const linkGroups = computed(() => registryLinkGroups.map((group) => {
    const filteredItemsAllowance = isSignedIn.value
      ? group.items.filter(({ checkAllowance }) => (checkAllowance ? allowedOccasions.value.includes(checkAllowance) : true))
      : group.items;

    return {
      ...group,
      items: filteredItemsAllowance,
    };
  }));
  const showSignIn = computed(() => !isSignedIn.value);

  return {
    hasRegestries, showSignIn, occasions, linkGroups,
  };
}
